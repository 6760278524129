<template>
    <div class="empty-box">
        <div class="empty-icon"></div>
        <p>暂无内容</p>
    </div>
</template>

<script>
	export default {
		name:"empty-box",
        data(){
			return{
				emptyIcon:require('@/assets/images/empty.png')
            }
        }
	}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
    @import "~@/assets/style/public.scss";
    .empty-box{
        flex:1;
        text-align:center;
        padding:30px 0 0;
        .empty-icon{
            background:url("~@/assets/images/empty.png") no-repeat;
            background-size:cover;
            width:176px;
            height:150px;
            margin:0 auto;
        }
        p{
            font-size:26px;
            color:rgb(153,153,153);
            padding:30px 0;
            align-content:center;
        }
    }
</style>